import styled from '@emotion/styled';

export const LogoGiteFrance = styled.img`
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 3rem;
  z-index: 5;
  @media (min-width: 992px) {
    top: 5rem;
    right: 2rem;
    width: 4rem;
  }
`;

export const Content = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spaces.md};

  .iframe {
    background: #f6f7f6;
  }
`;

export const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  .map {
    box-shadow: ${({ theme }) => theme.shadows.boxShadowLight};
  }
  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const Box = styled.section`
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadows.boxShadow};
  margin-bottom: ${({ theme }) => theme.spaces.lg};
  padding: ${({ theme }) => theme.spaces.md};
  border-radius: ${({ theme }) => theme.borders.borderRadius};
`;

export const Hr = styled.hr`
  height: 0;
  margin-top: 2.5rem;
  border: none;
  border-top: 1px solid #ddd;
`;

export const FormWrapper = styled(Box)``;

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.md};
  input,
  textarea {
    padding: ${({ theme }) => theme.spaces.sm};
    border: ${({ theme }) => theme.borders.border};
    border-radius: ${({ theme }) => theme.borders.borderRadius};
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  border: 1px solid ${({ theme }) => theme.colors.green};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 34px;
  text-align: center;
  text-decoration: none;
  margin: ${({ theme }) => theme.spaces.md} auto;
  border-radius: ${({ theme }) => theme.borders.borderRadius};
  transition: color 0.3s, background-color 0.3s;
  :hover,
  :focus {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.green};
    cursor: pointer;
  }
`;

export const MenuButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.smallSize};
  width: 3rem;
  height: 1.5rem;
  margin: 0;
  background-color: transparent;
  border-color: ${({ theme }) => theme.colors.greyDark};
  color: ${({ theme }) => theme.colors.greyDark};
  :hover,
  :focus {
    color: ${({ theme }) => theme.colors.greyDark};
    cursor: pointer;
  }
`;
