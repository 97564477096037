import React from 'react';
import { Link } from 'gatsby';

import { FooterContainer } from './styled';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer className="footer">
      <span>{`© ${currentYear} - Gites en Pyrénées Cathares`}</span>
      <span style={{ margin: '0 0.5rem' }}>{`-`}</span>
      <Link to="/mentions-legales">Mentions légales et crédits</Link>
    </FooterContainer>
  );
};

export default Footer;
