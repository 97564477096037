import styled from '@emotion/styled';

export const NavTouchHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  .navtouch-header {
    &__button {
      display: flex;
      padding: 0;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    &__button-icon {
      width: 2rem;
      height: 2rem;
      color: #999;
    }
  }
`;
