import React from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: `translateX(350px)`,
  position: `absolute`,
  top: 0,
  right: 0,
};

const transitionStyles = {
  entering: { transform: `translateX(0)` },
  entered: { transform: `translateX(0)` },
  exiting: { transform: `translateX(350px)` },
  exited: { transform: `translateX(350px)` },
};

const SlideMenu = ({ isOpen, children }) => (
  <Transition in={isOpen} timeout={duration}>
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

export default SlideMenu;
