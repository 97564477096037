const modularScale = 1.3;
const fontSizeBase = 1;
const bodySize = fontSizeBase;
const smallSize = 0.8;
const s4 = bodySize * modularScale;
const s3 = s4 * modularScale;
const s2 = s3 * modularScale;
const s1 = s2 * modularScale;

const space = 1;

const sandLight = '#fefaf1';
const green = 'rgb(120, 200, 120)';
const greyDark = '#333';
const greyLight = '#dedede';
const blue = '#2B98F0';

export default {
  fonts: {
    bodySize: `${bodySize}rem`,
    smallSize: `${smallSize}rem`,
    s4: `${s4}rem`,
    s3: `${s3}rem`,
    s2: `${s2}rem`,
    s1: `${s1}rem`,
  },
  colors: {
    sandLight,
    blue,
    green,
    greyDark,
    greyLight,
    link: blue,
  },
  spaces: {
    sm: `${space / 2}rem`,
    md: `${space}rem`,
    lg: `${space * 2}rem`,
    xl: `${space * 4}rem`,
  },
  borders: {
    border: `1px solid ${greyLight}`,
    borderRadius: `0.25rem`,
  },
  shadows: {
    boxShadowLight: `0 0 3px rgba(0, 0, 0, 0.25)`,
    boxShadow: `0 0 6px rgba(0, 0, 0, 0.2)`,
    boxShadow1: `0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2)`,
  },
};
