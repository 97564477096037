import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import Media from 'react-media';
import { FaFacebookSquare } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import {
  NavTouchWrapper,
  NavTouch,
  NavTouchLinks,
} from '../../components/shared/styled/nav';
import SlideMenu from '../shared/SlideMenu';
import { MenuButton } from '../shared/styled/layout';
import { FacebookLink } from '../shared/styled/nav';
import { NavTouchHeader } from './styled';
import logoGitesFrance from '../../images/gites-icon.png';

import './Header.scss';

function useOutsideAlerter(ref, isOpen, setIsOpen) {
  function handleClickOutside(event) {
    if (isOpen && ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const OutsideAlerter = ({ isOpen, children, setIsOpen }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, isOpen, setIsOpen);
  return <div ref={wrapperRef}>{children}</div>;
};

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <Location>
        {({ location }) => {
          return location.pathname === '/' ? (
            <h1 className="header__logo">
              <Link to="/">{siteTitle}</Link>
            </h1>
          ) : (
            <div className="header__logo">
              <Link to="/" className="header__logo">
                {siteTitle}
              </Link>
            </div>
          );
        }}
      </Location>

      <Media query={{ maxWidth: 992 }}>
        {matches =>
          matches ? (
            <>
              <MenuButton onClick={toggleMenu}>MENU</MenuButton>

              <SlideMenu isOpen={isOpen}>
                <OutsideAlerter isOpen={isOpen} setIsOpen={setIsOpen}>
                  <NavTouchWrapper>
                    <NavTouchHeader>
                      <button
                        onClick={toggleMenu}
                        className="navtouch-header__button"
                      >
                        <MdClose className="navtouch-header__button-icon" />
                      </button>
                    </NavTouchHeader>
                    <NavTouch>
                      <Link
                        onClick={toggleMenu}
                        className="navtouch__link"
                        to="/"
                        activeClassName="active"
                      >
                        Accueil
                      </Link>
                      <Link
                        onClick={toggleMenu}
                        className="navtouch__link"
                        to="/grange-neuve"
                        activeClassName="active"
                      >
                        Gîte La Grange Neuve
                      </Link>
                      <Link
                        onClick={toggleMenu}
                        className="navtouch__link"
                        to="/notre-region"
                        activeClassName="active"
                      >
                        Notre région
                      </Link>
                      <Link
                        onClick={toggleMenu}
                        className="navtouch__link"
                        to="/contact"
                        activeClassName="active"
                      >
                        Contact
                      </Link>
                      <NavTouchLinks>
                        <FacebookLink
                          navTouch
                          href="https://www.facebook.com/Gites-cathares-ari%C3%A8ge-pyr%C3%A9n%C3%A9es-face-au-ch%C3%A2teau-de-Monts%C3%A9gur-254822701321404/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFacebookSquare
                            style={{
                              width: '2.5rem',
                              height: '2.5rem',
                              color: 'rgb(39,99,155)',
                            }}
                          />
                        </FacebookLink>
                        <a
                          style={{ display: 'flex', alignItem: 'center' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://www.gites-de-france-ariege.com/sr0111-6-pers-montferrier/montferrier/tabid/25179/offreid/6a69823c-7052-43cc-bb42-7b629ffb8dcf/detail.aspx"
                        >
                          <img
                            src={logoGitesFrance}
                            alt="logo Gîte de France"
                            style={{ width: '2.5rem', height: '2.5rem' }}
                          />
                        </a>
                      </NavTouchLinks>
                    </NavTouch>
                  </NavTouchWrapper>
                </OutsideAlerter>
              </SlideMenu>
            </>
          ) : (
            <>
              <nav className="header__nav">
                <Link
                  className="header__nav-item"
                  to="/"
                  activeClassName="active"
                >
                  Accueil
                </Link>
                <Link
                  className="header__nav-item"
                  to="/grange-neuve"
                  activeClassName="active"
                >
                  Gîte La Grange Neuve
                </Link>
                <Link
                  className="header__nav-item"
                  to="/notre-region"
                  activeClassName="active"
                >
                  Notre région
                </Link>
                <Link
                  className="header__nav-item"
                  to="/contact"
                  activeClassName="active"
                >
                  Contact
                </Link>
              </nav>
              <a
                style={{
                  display: 'flex',
                  alignItem: 'center',
                  marginLeft: '0.5rem',
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.gites-de-france-ariege.com/sr0111-6-pers-montferrier/montferrier/tabid/25179/offreid/6a69823c-7052-43cc-bb42-7b629ffb8dcf/detail.aspx"
              >
                <img
                  src={logoGitesFrance}
                  alt="logo Gîte de France"
                  style={{ width: '2rem' }}
                />
              </a>
              <FacebookLink
                href="https://www.facebook.com/Gites-cathares-ari%C3%A8ge-pyr%C3%A9n%C3%A9es-face-au-ch%C3%A2teau-de-Monts%C3%A9gur-254822701321404/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare
                  style={{
                    width: '2rem',
                    height: '2rem',
                    color: 'rgb(39,99,155)',
                  }}
                />
              </FacebookLink>
            </>
          )
        }
      </Media>
    </header>
  );
};

export default Header;
