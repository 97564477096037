import styled from '@emotion/styled';

export const NavTouchWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 310px;
  padding: 1rem;
  z-index: 50;
  background-color: #fff;
  box-shadow: ${props => props.theme.shadows.boxShadow};
`;

export const NavTouch = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  .navtouch__link {
    padding: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: ${props => props.theme.borders.borderRadius};
    color: #666;
    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const NavTouchLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spaces.md};
`;

export const FacebookLink = styled.a`
  margin-left: ${props => (props.navTouch ? '0' : '1rem')};
  margin-right: ${props => (props.navTouch ? '1rem' : '0')};
  display: flex;
  align-items: center;
`;
