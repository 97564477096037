import styled from '@emotion/styled';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  font-size: ${props => props.theme.fonts.smallSize};
  color: ${props => props.theme.colors.greyDark};
  background: rgba(255, 255, 255, 0.9);
  box-shadow: ${props => props.theme.shadows.boxShadow};
  border-top: ${props => props.theme.borders.border};
`;
